import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DogContainer from "../components/HomePage/DogContainer";
import SponsorContainer from "../components/HomePage/SponsorContainer";
import { announcements } from "../api/announcements";
import { showcaseDogs } from "../api/showcaseDogs";
import { showcaseSponsors } from "../api/showcaseSponsors";
import LoadingSpinner from "../components/Spinner";

const Homepage: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselImages, setCarouselImages] = useState<string[] | undefined>(
    undefined,
  );
  const [videoPlaying, setVideoPlaying] = useState(false);
  const navigate = useNavigate();

  const videoId = "zLoilYQJ_Mg";
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

  interface Dog {
    name: string;
    sex: string;
    breed: string;
    image: string;
    linkTo: string;
  }
  interface Sponsor {
    name: string;
    image: string;
  }

  const sexOptions = [
    { male: "Male" },
    { neutered_male: "Neutered_Male" },
    { female: "Female" },
    { spayed_female: "Spayed_Female" },
  ];

  const [showcasedDogs, setShowcasedDogs] = useState<Dog[]>([]);
  const [showcasedSponsors, setShowcasedSponsors] = useState<Sponsor[]>([]);

  const getDogSex = (sex: string): string => {
    const sexOption = sexOptions.find((option) =>
      Object.keys(option).includes(sex),
    );
    return sexOption
      ? Object.values(sexOption)[0].replace("_", " ")
      : "Unknown";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await announcements();
        const images = response.map(
          (announcement: { image: string }) => announcement.image,
        );
        setCarouselImages(images);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };
    const fetchShowcaseDogs = async () => {
      try {
        const response = await showcaseDogs();
        const dogs = response[0].dogs;
        console.log("dogs", dogs);

        const adoptableDogs = dogs.map((dog: any) => ({
          name: dog.dog.name,
          sex: getDogSex(dog.dog.sex),
          breed: dog.dog.breed,
          image: dog.dog.images[0].image || "",
          linkTo: `/browse/${dog.dog.dog_id}`,
        }));
        setShowcasedDogs(adoptableDogs);
      } catch (error) {
        console.error("Error fetching showcase dogs:", error);
      }
    };
    const fetchSponsors = async () => {
      try {
        const response = await showcaseSponsors();

        const sponsors = response[0].sponsors;
        const temp_sponsors = sponsors.map((sponsor: any) => ({
          name: sponsor.sponsor.name,
          image: sponsor.sponsor.logo,
        }));

        setShowcasedSponsors(temp_sponsors);
      } catch (error) {
        console.error("Error fetching showcase sponsors:", error);
      }
    };

    //these methods fix the width of the carousel when the scrollbar appears. Avoiding issue causing a horizontal scrollbar to appear
    const adjustWidth = () => {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.documentElement.style.setProperty(
        "--adjusted-width",
        `calc(100vw - ${scrollbarWidth}px)`,
      );
    };

    const manageResizeListener = (action: "add" | "remove") => {
      const method =
        action === "add" ? "addEventListener" : "removeEventListener";
      window[method]("resize", adjustWidth);
    };
    fetchAnnouncements();
    fetchShowcaseDogs();
    fetchSponsors();
    adjustWidth();
    manageResizeListener("add");
  }, []);

  const handlePrevClick = () => {
    if (carouselImages) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1,
      );
    }
  };

  const handleNextClick = () => {
    if (carouselImages) {
      setCurrentIndex((prevIndex) =>
        prevIndex === carouselImages.length - 1 ? 0 : prevIndex + 1,
      );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextClick();
    }, 10000); // Change the image every x seconds

    return () => clearInterval(timer); // Clear the timer when the component unmounts or currentIndex changes

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, carouselImages]);

  if (!carouselImages) {
    return <LoadingSpinner />;
  }

  return (
    <div className="mt-0.5 flex min-h-screen w-full flex-col items-center font-poppins">
      {/* Image Carousel. Currently not used; saved for the future */}
      {/* <section id="dog carousel" className="flex flex-col items-center">
        <div
          id="dog-showcase"
          className="relative flex flex-row items-center overflow-hidden"
          style={{ width: "var(--adjusted-width)" }}
        >
          <div
            className="flex h-fit transition-transform duration-700 ease-in-out md:duration-1000"
            style={{
              transform: `translateX(-${currentIndex * 100}vw)`,
            }}
          >
            {carouselImages.map((src, index) => (
              <div
                key={index}
                // adjust height here for carousel, 817 is figma file, 600 is 25% reduction.}
                className="flex h-600 items-center justify-center"
                style={{
                  width: "100vw",
                }}
              >
                <img
                  src={src}
                  alt={`Showcase ${index}`}
                  className="h-full object-contain"
                />
              </div>
            ))}
          </div>
          <button
            onClick={handlePrevClick}
            className="absolute left-4 top-1/2 z-20 flex size-10 -translate-y-1/2 transform items-center justify-center rounded-full bg-blue hover:bg-blue-dark md:size-14"
          >
            <img
              src="/images/svgs/left-arrow.svg"
              alt="left-arrow"
              className="mr-1 size-7 md:size-10"
            ></img>
          </button>
          <button
            onClick={handleNextClick}
            className="absolute right-4 top-1/2 z-20 flex size-10 -translate-y-1/2 transform items-center justify-center rounded-full bg-blue hover:bg-blue-dark md:size-14"
          >
            <img
              src="/images/svgs/right-arrow.svg"
              alt="right-arrow"
              className="ml-1 size-7 md:size-10"
            ></img>
          </button>
        </div>
        <div className="mt-5 flex justify-center gap-3">
          {carouselImages.map((_, index) => (
            <div
              key={index}
              className={`size-21 rounded-full transition-colors ${currentIndex === index ? "bg-blue" : "bg-gray-dark"}`}
            ></div>
          ))}
        </div>
      </section> */}

      {/* Top Section */}
      <section className="flex w-full flex-col px-0 py-0 pb-6 xl:px-28 xl:px-6 xl:py-10 xl:pb-0 2xl:px-32">
        <h1 className="mx-auto mb-6 hidden text-center text-3xl font-bold text-blue xl:block">
          At-Risk Dogs Need You Now: Give Them a Forever Home
        </h1>
        <div className="flex flex-col gap-6 sm:gap-10 xl:flex-row">
          <div className="h-[250px] w-full flex-none overflow-hidden sm:h-[450px] xl:h-[466px] xl:w-[749px]">
            <img
              src="/images/png-jpg/homepage/mainDog.jpg"
              className="h-full w-full object-cover object-[50%_25%]"
              alt="dog on beach"
            />
          </div>
          <div className="w-full px-4 xl:px-0">
            <h1 className="mb-4 block text-center text-xl font-bold text-blue sm:text-2xl xl:hidden">
              At-Risk Dogs Need You Now: Give Them a Forever Home
            </h1>
            <p className="mb-6 text-center text-base font-normal text-cmsignintext sm:text-xl xl:text-left xl:text-2xl">
              Pup Profile is a passionate, volunteer-run nonprofit with a big
              heart for dogs, founded in 2024. Our team is made up of a unique
              mix of professionals, military veterans, dog rescuers and MBAs,
              all united by one thing: a deep love for dogs.
              <br />
              <br />
              Together, we’re committed to making a difference, one pup at a
              time.
            </p>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
              {[
                { label: "Available Pups", link: "/browse" },
                { label: "Get Involved", link: "/get-involved" },
                { label: "Helpful Resources", link: "/resources" },
                { label: "About Us", link: "/about" },
                { label: "Shop Our Products", link: "/shop" },
                { label: "Donate Now", link: "/donate", bgColor: "!bg-yellow" },
              ].map(({ label, link, bgColor = "bg-blue" }, index) => (
                <button
                  key={index}
                  onClick={() => {
                    navigate(link);
                    window.scrollTo(0, 0);
                  }}
                  className={`h-12 w-full rounded-md p-1 text-sm font-bold text-white transition sm:h-16 sm:w-auto sm:text-base 2xl:text-lg ${bgColor} hover:bg-blue-dark`}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Our mission */}
      <section
        id="our-mission"
        className="flex w-full flex-col-reverse items-center gap-6 px-0 py-0 pb-8 sm:pb-0 lg:gap-10 xl:flex-row xl:px-28 xl:px-6 xl:py-10 2xl:px-32"
      >
        <div className="flex w-full flex-col items-center gap-6 px-4 sm:items-start sm:gap-8 xl:w-1/2 xl:px-0">
          <h1 className="mx-auto text-center text-xl font-bold text-blue lg:text-left lg:text-4xl xl:text-6xl">
            Our Mission
          </h1>
          <p className="text-center text-base font-normal text-cmsignintext lg:text-left lg:text-2xl xl:text-3xl">
            At Pup Profile, our mission is to provide a platform to showcase &
            support dogs who are at risk of being euthanized at overcrowded
            shelters.
          </p>
          <button
            onClick={() => {
              navigate("/get-involved");
              window.scrollTo(0, 0);
            }}
            className="mx-auto h-12 w-40 rounded-md bg-blue transition hover:bg-blue-dark sm:h-16 sm:w-64"
          >
            <p className="font-poppins text-sm font-bold text-white sm:text-lg">
              Make an Impact
            </p>
          </button>
        </div>
        <div className="w-full xl:w-1/2">
          <img
            src="/images/png-jpg/homepage/missionDog.png"
            className="h-auto w-full object-cover"
            alt="dog on beach"
          />
        </div>
      </section>

      {/* How to Get Involved */}
      <section className="mb-8 flex w-full flex-col items-center px-0 py-0 font-poppins sm:mb-0 sm:py-14 xl:px-8">
        <h1 className="mb-4 text-center text-xl font-bold text-blue sm:mb-6 sm:text-3xl">
          How to Get Involved
        </h1>
        <div className="flex flex-col flex-wrap items-center justify-center gap-4 sm:flex-row sm:gap-8 lg:gap-14 xl:gap-24">
          <div className="w-[240px] sm:w-[380px]">
            <img
              src="/images/png-jpg/homepage/dog-1.png"
              alt="black lab"
              className="h-[240px] w-[240px] object-cover sm:h-[402px] sm:w-[380px]"
            />
            <div className="flex h-[140px] flex-col items-center justify-end">
              <h2 className="text-base font-semibold text-gray1 sm:text-2xl">
                Foster
              </h2>
              <p className="text-center text-base font-medium text-gray3">
                Open your home and save a life.
              </p>
              <button
                onClick={() => {
                  navigate("/foster-interest");
                  window.scrollTo(0, 0);
                }}
                className="mx-auto mt-2 h-10 w-48 rounded-10 rounded-5 bg-blue transition hover:bg-blue-dark sm:h-14 sm:h-16 sm:w-64"
              >
                <p className="font-poppins text-sm font-bold text-white sm:text-lg">
                  Foster Interest Form
                </p>
              </button>
            </div>
          </div>

          <div className="w-[240px] sm:w-[380px]">
            <img
              src="/images/png-jpg/homepage/dog-2.png"
              alt="white and brown husky"
              className="h-[240px] w-[240px] object-cover sm:h-[402px] sm:w-[380px]"
            />
            <div className="flex h-[140px] flex-col items-center justify-end">
              <h2 className="text-base font-semibold text-gray1 sm:text-2xl">
                Adopt
              </h2>
              <p className="text-center text-base font-medium text-gray3">
                Your new best friend is waiting!
              </p>
              <button
                onClick={() => {
                  navigate("/browse");
                  window.scrollTo(0, 0);
                }}
                className="mx-auto mt-2 h-10 w-48 rounded-10 rounded-5 bg-blue transition hover:bg-blue-dark sm:h-14 sm:h-16 sm:w-64"
              >
                <p className="font-poppins text-sm font-bold text-white sm:text-lg">
                  Browse Adoptable Dogs
                </p>
              </button>
            </div>
          </div>

          <div className="w-[240px] sm:w-[380px]">
            <img
              src="/images/png-jpg/homepage/dog-3.png"
              alt="bigger black lab"
              className="h-[240px] w-[240px] object-cover sm:h-[402px] sm:w-[380px]"
            />
            <div className="flex h-[140px] flex-col items-center justify-end">
              <h2 className="text-base font-semibold text-gray1 sm:text-2xl">
                Donate to The Pups Fund
              </h2>
              <p className="text-center text-base font-medium text-gray3">
                Support a dog in immediate need.
              </p>
              <button
                onClick={() => {
                  navigate("/donate");
                  window.scrollTo(0, 0);
                }}
                className="mx-auto mt-2 h-10 w-48 rounded-10 rounded-5 bg-blue transition hover:bg-blue-dark sm:h-14 sm:h-16 sm:w-64"
              >
                <p className="font-poppins text-sm font-bold text-white sm:text-lg">
                  Donate Here
                </p>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Who we are */}
      <section
        id="who-we-are"
        className="flex h-auto w-full flex-col items-center pb-8 sm:min-h-450 sm:pb-0"
      >
        <h2 className="mb-2 text-center text-xl font-bold text-blue sm:text-3xl xl:mb-6">
          Who We Are
        </h2>
        <div
          id="video-container"
          className="relative mt-6 h-fit w-full bg-black"
        >
          {!videoPlaying ? (
            <div
              className="flex h-full w-full cursor-pointer items-center justify-center"
              onClick={() => setVideoPlaying(true)}
            >
              <img
                src={thumbnailUrl}
                alt="Video Thumbnail"
                className="flex h-216 h-full max-h-450 w-auto items-center justify-center object-cover lg:h-450"
              />
              <button className="absolute">
                <img src="/images/svgs/play-button.svg" alt="Play" />
              </button>
            </div>
          ) : (
            <div className="relative h-216 max-h-450 w-full overflow-hidden lg:h-450">
              <iframe
                id="myIframe"
                className="absolute left-0 top-0 h-full w-full overflow-hidden"
                src="https://pup-profile-prod.s3.us-west-2.amazonaws.com/about/Who+We+Are.mp4"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              ></iframe>
            </div>
          )}
        </div>
      </section>

      {/* Adoptable dogs */}
      <section
        id="adobtable-dogs-and-sponsors"
        className="w-full px-0 sm:py-4 lg:px-8 xl:py-8"
      >
        <h2 className="text-center text-xl font-bold text-blue sm:text-3xl">
          Find Your Pup
        </h2>
        <div
          className={`mb-8 mt-7 flex flex-col flex-wrap items-center justify-center gap-6 sm:flex-row sm:justify-center ${
            showcasedDogs.length >= 4 && "sm:!gap-6"
          }`}
        >
          {showcasedDogs.map((dog, index) => (
            <DogContainer
              key={index}
              name={dog.name}
              sex={dog.sex}
              breed={dog.breed}
              image={dog.image}
              linkTo={dog.linkTo}
            />
          ))}
        </div>
      </section>

      {/* Sponsor showcase */}
      {showcasedSponsors && showcasedSponsors.length > 0 && (
        <section className="w-full px-0 py-0 sm:py-4 md:px-12 lg:px-8 xl:py-8">
          <h2 className="text-center text-xl font-bold text-blue sm:text-3xl">
            Our Sponsors
          </h2>
          <div
            className={`mb-8 mt-7 flex flex-col flex-wrap items-center justify-center gap-6 sm:flex-row sm:justify-center ${
              showcasedSponsors.length >= 4 && "sm:!gap-6"
            }`}
          >
            {showcasedSponsors.map((sponsor, index) => (
              <SponsorContainer
                key={index}
                name={sponsor.name}
                image={sponsor.image}
              />
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default Homepage;
