import { Link } from "react-router-dom";

interface DogContainerProps {
  //probably need to be adjusted depending on how we want to setup a specific dog page and backend interactions
  image: string;
  name: string;
  sex: string;
  breed: string;
  linkTo: string;
}

const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

const DogContainer: React.FC<DogContainerProps> = ({
  image,
  name,
  sex,
  breed,
  linkTo,
}) => {
  const shortBreed = truncateText(breed, 21);

  return (
    <>
      <Link
        to={linkTo}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        className="flex flex-col items-center font-poppins"
      >
        <img
          src={image}
          alt={`${name}`}
          className="h-[240px] w-[240px] object-cover sm:h-[350px] sm:w-[300px]"
        ></img>
        <div className="flex h-[74px] w-[240px] flex-col justify-center bg-blue pl-4 text-left text-base text-white sm:h-[145px] sm:w-[300px] sm:text-2xl">
          <h1 className="font-semibold">{name}</h1>
          <h2 className="font-normal"> {sex} </h2>
          <h2 className="font-normal"> {shortBreed} </h2>
        </div>
      </Link>
    </>
  );
};
export default DogContainer;
