import React, { useState, useEffect, useRef } from "react";
import { useGlobal } from "../Context/GlobalContext";
import { retrieveAllDogs } from "../api/retrieveAllDogs";
import { publicDogModel } from "../types/publicDogModel";
import PublicBrowseDogCard from "../components/PublicBrowseDogCard";
import MobilePublicBrowseDogCard from "../components/MobilePublicBrowseDogCard";
import up from "../images/up-arrow.svg";
import down from "../images/down-arrow.svg";
import { Link } from "react-router-dom";
import { retrievePaginatedDogs } from "../api/retrievePaginatedDogs";
import { retrieveAllDogLocations } from "../api/retrieveDogLocations";
import LoadingSpinner from "../components/Spinner";
import { sampleDog } from "../types/sampleDog";

interface BrowseDogsProps {
  statusProp: "atRisk" | "inRescueOrFoster" | "adopted" | "allDogs";
}

const BrowseDogs: React.FC<BrowseDogsProps> = ({ statusProp }) => {
  const [locations, setLocations] = useState<location[]>([]);
  type location = {
    city: string;
    state: string;
  };
  const [filters, setFilters] = useState({
    locationFilters: [] as string[],
    browseDogsFilter: [] as string[],
    sexFilter: [] as string[],
    sizeFilters: [] as string[],
    ageFilters: [] as string[],
    statusFilters: [] as string[],
  });
  const sampleDogs = [sampleDog, sampleDog, sampleDog];
  const [isMobile, setIsMobile] = useState(false);
  const [loadingMoreDogs, setLoadingMoreDogs] = useState(false);
  const [totalCalls, setTotalCalls] = useState(0);
  const {
    params,
    setParams,
    browseChange,
    allDogs,
    setAllDogs,
    fetchedParams,
    setFetchedParams,
    page,
    setPage,
    browseScroll,
    setBrowseScroll,
  } = useGlobal();

  const [dataExists, setDataExists] = useState(false);
  const previousState = useRef<{ page: number; params: any }>({
    page,
    params,
  });

  useEffect(() => {
    const state = {
      page,
      filters,
      browseScroll,
    };

    localStorage.setItem("browseDogsState", JSON.stringify(state));
  }, [page, filters, browseScroll]);

  useEffect(() => {
    const savedState = localStorage.getItem("browseDogsState");

    if (savedState) {
      const { page, filters, browseScroll } = JSON.parse(savedState);
      setPage(page || 1);
      setFilters(filters || {});
      setBrowseScroll(browseScroll || 0);

      // Restore scroll position
      setTimeout(() => {
        window.scrollTo(0, browseScroll || 0);
      }, 0);
    }
  }, []);

  useEffect(() => {
    // Restore scroll position
    setTimeout(() => {
      window.scrollTo(0, browseScroll);
    }, 0);
  }, []);

  const handleScroll = () => {
    setBrowseScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const arraysEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  };

  const checkHeaderFilterChange = () => {
    if (arraysEqual(params.adoptable_status, fetchedParams.adoptable_status)) {
      return false;
    }
    return true;
  };

  const setLocationFilters = (
    locations: { city: string | null; state: string | null }[],
  ) => {
    const uniqueLocations: { city: string; state: string }[] = [];
    locations.forEach((location) => {
      const { city, state } = location;
      if (city && state) {
        const locationExists = uniqueLocations.some(
          (loc) => loc.city === city && loc.state === state,
        );

        if (!locationExists) {
          uniqueLocations.push({ city, state });
        }
      }
    });
    setLocations(uniqueLocations);
  };
  const retrieveDogs = async (page: number, params: any) => {
    try {
      setLoadingMoreDogs(true);
      const response = await retrievePaginatedDogs(page, params);

      if (page === 1) {
        setAllDogs(response.results);
      } else {
        setAllDogs([...allDogs, ...response.results]);
      }
      if (!response.next) {
        setDataExists(false);
      } else {
        setDataExists(true);
      }
      setFetchedParams(params);

      setLoadingMoreDogs(false);
      setTimeout(() => {
        setTotalCalls(totalCalls + 1);
      }, 300);
    } catch (error) {
      setLoadingMoreDogs(false);
      setTimeout(() => {
        setTotalCalls(totalCalls + 1);
      }, 300);
      console.error("Failed to fetch all dogs data: ", error);
    }
  };
  const retrieveDataExists = async (page: number, params: any) => {
    try {
      setLoadingMoreDogs(true);
      const response = await retrievePaginatedDogs(page, params);
      if (!response.next) {
        setDataExists(false);
      } else {
        setDataExists(true);
      }
      setFetchedParams(params);
      setLoadingMoreDogs(false);

      setTimeout(() => {
        setTotalCalls(totalCalls + 1);
      }, 300);
    } catch (error) {
      setLoadingMoreDogs(false);
      setTimeout(() => {
        setTotalCalls(totalCalls + 1);
      }, 300);
      console.error("Failed to fetch all dogs data: ", error);
    }
  };

  useEffect(() => {
    const fetchDogData = async () => {
      const savedState = localStorage.getItem("browseDogsState");
      const savedPage = savedState ? JSON.parse(savedState).page : null;

      // If we already have the data for the current page, skip fetching
      if (
        allDogs.length > 0 &&
        !checkHeaderFilterChange() &&
        previousState.current.page === page
      ) {
        window.scrollTo(0, browseScroll);
        return;
      }

      // Fetch new data
      if (allDogs.length > 0 && !checkHeaderFilterChange()) {
        retrieveDataExists(page, params);
      } else {
        const response = await retrieveAllDogLocations();
        setLocationFilters(response);

        if (!page && savedPage) {
          setPage(savedPage);
        } else {
          setPage(1);
        }

        retrieveDogs(page || 1, params);
      }

      // Update previous state after fetch
      previousState.current = { page, params };
    };

    setFilters({
      locationFilters: [],
      browseDogsFilter: [],
      sexFilter: [],
      sizeFilters: [],
      ageFilters: [],
      statusFilters: [],
    });

    fetchDogData();
  }, []);

  // Update previous state whenever page or params change
  useEffect(() => {
    previousState.current = { page, params };
  }, [page, params]);

  useEffect(() => {
    setFilters({
      locationFilters: [],
      browseDogsFilter: [],
      sexFilter: [],
      sizeFilters: [],
      ageFilters: [],
      statusFilters: [],
    });
  }, []);

  const paramConversion = [
    { filter: "At-Risk", param: "at_risk" },
    { filter: "In Rescue/Foster Care", param: "in_rescue_or_foster" },
    { filter: "Browse All Dogs", param: "" },

    { filter: "Male", param: "male" },
    { filter: "Female", param: "female" },
    { filter: "<15lbs", param: "15lbs" },
    { filter: "16-30lbs", param: "16-30lbs" },
    { filter: "31-50lbs", param: "31-50lbs" },
    { filter: "51-80lbs", param: "51-80lbs" },
    { filter: ">80lbs", param: "80lbs" },

    { filter: "<6 months", param: "6 months" },
    { filter: "7 months - 3 years", param: "7 months - 3 years" },
    { filter: "4 - 6 years", param: "4 - 6 years" },
    { filter: ">6 years", param: "6 years" },
  ];

  const filterToParam = (filter: string) => {
    const conversion = paramConversion.find((item) => item.filter === filter);
    return conversion ? conversion.param : filter;
  };

  const paramToFilter = (param: string) => {
    const conversion = paramConversion.find((item) => item.param === param);
    return conversion ? conversion.filter : param;
  };

  // Set filters to saved params
  useEffect(() => {
    setFilters({
      locationFilters: params.location,
      browseDogsFilter: params.adoptable_status.map(paramToFilter),
      sexFilter: params.sex.map(paramToFilter),
      sizeFilters: params.size.map(paramToFilter),
      ageFilters: params.age.map(paramToFilter),
      statusFilters: params.status.map(paramToFilter),
    });
  }, [browseChange]);

  // set params to filters onchange of filters
  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      location: filters.locationFilters,
      adoptable_status: filters.browseDogsFilter.map(filterToParam),
      sex: filters.sexFilter.map(filterToParam),
      size: filters.sizeFilters.map(filterToParam),
      age: filters.ageFilters.map(filterToParam),
      status: filters.statusFilters.map(filterToParam),
    }));
  }, [filters]);

  useEffect(() => {
    if (totalCalls !== 0) {
      setPage(1);
      retrieveDogs(1, params);
    }
  }, [
    params.adoptable_status,
    params.location,
    params.age,
    params.sex,
    params.status,
    params.size,
  ]);

  const handleBrowseButtonsClick = (data: string, filterType: string) => {
    switch (filterType) {
      case "Browse Dogs":
        setFilters((prevFilters) => ({
          ...prevFilters,
          browseDogsFilter: [data],
        }));
        break;
      default:
        break;
    }
  };

  const handleFilterCheckboxClick = (data: string, filterType: string) => {
    const tempFilters = { ...filters };
    switch (filterType) {
      case "Location":
        if (tempFilters.locationFilters.includes(data)) {
          tempFilters.locationFilters = tempFilters.locationFilters.filter(
            (location) => location !== data,
          );
        } else {
          tempFilters.locationFilters = [...tempFilters.locationFilters, data];
        }
        break;
      case "Browse Dogs":
        if (tempFilters.browseDogsFilter.includes(data)) {
          tempFilters.browseDogsFilter = tempFilters.browseDogsFilter.filter(
            (filter) => filter !== data,
          );
        } else {
          tempFilters.browseDogsFilter = [
            ...tempFilters.browseDogsFilter,
            data,
          ];
        }
        // Ensure "Browse All Dogs" is not included
        tempFilters.browseDogsFilter = tempFilters.browseDogsFilter.filter(
          (filter) => filter !== "Browse All Dogs",
        );
        // Ensure "Browse All Dogs" is selected if no other filters are active
        if (
          tempFilters.browseDogsFilter.length === 0 ||
          (tempFilters.browseDogsFilter.includes("At-Risk") &&
            tempFilters.browseDogsFilter.includes("In Rescue or Foster"))
        ) {
          tempFilters.browseDogsFilter = ["Browse All Dogs"];
        }

        break;
      case "Sex":
        if (tempFilters.sexFilter.includes(data)) {
          tempFilters.sexFilter = [];
        } else {
          tempFilters.sexFilter = [data];
        }
        break;

      case "Size":
        if (tempFilters.sizeFilters.includes(data)) {
          tempFilters.sizeFilters = tempFilters.sizeFilters.filter(
            (size) => size !== data,
          );
        } else {
          tempFilters.sizeFilters = [...tempFilters.sizeFilters, data];
        }
        break;
      case "Age":
        if (tempFilters.ageFilters.includes(data)) {
          tempFilters.ageFilters = tempFilters.ageFilters.filter(
            (age) => age !== data,
          );
        } else {
          tempFilters.ageFilters = [...tempFilters.ageFilters, data];
        }
        break;
      case "Status":
        if (tempFilters.statusFilters.includes(data)) {
          tempFilters.statusFilters = tempFilters.statusFilters.filter(
            (status) => status !== data,
          );
        } else {
          tempFilters.statusFilters = [...tempFilters.statusFilters, data];
        }
        break;
      default:
        break;
    }
    setFilters(tempFilters);
  };

  const filterMapping: { [key: string]: string[] } = {
    "Browse Dogs": filters.browseDogsFilter,
    Sex: filters.sexFilter,
    Size: filters.sizeFilters,
    Age: filters.ageFilters,
  };

  const getFilterArray = (sectionText: string): string[] => {
    return filterMapping[sectionText] || [];
  };

  const buttons = [
    {
      text: "At-Risk",
      filterText: "At-Risk",
    },
    {
      text: "In Rescue / Foster Care",
      filterText: "In Rescue/Foster Care",
    },
    {
      text: "Browse All Dogs",
      filterText: "Browse All Dogs",
    },
  ];

  const keys = [
    {
      text: "Red: Medical",
      filterText: "medical",
      color: "border-red-500",
      active_color: "bg-red-300",
    },
    {
      text: "Blue: Behavioral",
      filterText: "behavioral",
      color: "border-blue-500",
      active_color: "bg-blue-300",
    },
    {
      text: "Green: Adoptable",
      filterText: "adoptable",
      color: "border-green-500",
      active_color: "bg-green-300",
    },
  ];

  const filter = [
    {
      text: "Browse Dogs",
      content: ["At-Risk", "In Rescue/Foster Care"],
    },
    {
      text: "Sex",
      content: ["Male", "Female"],
    },
    {
      text: "Size",
      content: ["<15lbs", "16-30lbs", "31-50lbs", "51-80lbs", ">80lbs"],
    },
    {
      text: "Age",
      content: ["<6 months", "7 months - 3 years", "4 - 6 years", ">6 years"],
    },
  ];

  const [searchDropDownData, setSearchDropDownData] = useState({
    text: "Search By: ",
    content: [
      "At-Risk",
      "In Rescue / Foster Care",
      "Adopted",
      "Browse All Dogs",
    ],
    expanded: false,
  });
  const toggleSearchDropDown = () => {
    setSearchDropDownData((prevData) => ({
      ...prevData,
      expanded: !prevData.expanded,
    }));
  };

  const [filterDropDownData, setFilterDropDownData] = useState({
    text: "Filter By: ",
    expanded: false,
  });

  const toggleFilterDropDown = () => {
    setFilterDropDownData((prevData) => ({
      ...prevData,
      expanded: !prevData.expanded,
    }));
  };

  const [addCheckedStatusSearch, setAddCheckedStatusSearch] = useState({
    addAtRiskChecked: false,
    addInRescueChecked: false,
    addAdopted: false,
    addAll: false,
  });

  const handleLoadMoreButton = (e: any) => {
    e.preventDefault();
    setDataExists(false);
    retrieveDogs(page + 1, params);
    setPage(page + 1);
  };

  const handleAddDogCheckboxChange = (checkboxName: string) => {
    setAddCheckedStatusSearch((prevStatus: any) => {
      const newStatus: any = {
        addAtRiskChecked: false,
        addInRescueChecked: false,
        addAdopted: false,
        addAll: false,
      };

      if (!prevStatus[checkboxName]) {
        newStatus[checkboxName] = true;
      }

      return newStatus;
    });
  };

  return (
    <section className="mb-20 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light px-6 py-12 md:px-0">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-center text-3xl font-bold text-blue">
            Find your Pup
          </h1>
          <h2 className="text-center">
            Your new best friend is waiting! Our At-Risk dogs are still at the
            shelter and need help quickly. Can you be the hero they need by
            fostering, adopting, or donating?
          </h2>
        </div>
      </section>
      <div className="mx-auto my-20 max-w-[1500px] gap-10 px-6 md:px-3">
        <div className="hidden md:flex">
          <>
            <div className="flex flex-col items-center">
              <div className="flex w-full items-center justify-center"></div>
              <section className="flex w-full flex-row">
                <div className="mb-6 mr-10 mt-6 w-[300px] text-left">
                  <button className="mt-5 w-fit w-full text-nowrap rounded-md bg-blue py-2 font-semibold text-white shadow-md hover:bg-blue-dark">
                    <Link to="/adopted" className="">
                      Adopted Dogs
                    </Link>
                  </button>
                  <div className="mb-6 mt-5">
                    <h1 className="text-xl font-bold text-blue">Search by: </h1>
                    <h2 className="mt-2 text-lg font-bold">
                      ID #, Name or Breed:
                    </h2>
                    <input
                      className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                      id="email"
                      type="email"
                      value={params.search}
                      onChange={(e) =>
                        setParams({ ...params, search: e.target.value })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          // Trigger search or any other action
                          retrieveDogs(1, {
                            ...params,
                            search: (e.target as HTMLInputElement).value,
                          });
                        }
                      }}
                    />
                    <div className="mt-4 flex w-full justify-center">
                      <button
                        onClick={() => retrieveDogs(1, params)}
                        className="w-fit w-full text-nowrap rounded-md bg-blue py-2 font-semibold text-white shadow-md hover:bg-blue-dark"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="border border-gray-dark">
                    <h2 className="border-b border-gray-dark p-2 text-lg font-bold">
                      Filter By:
                    </h2>
                    <div className="p-2">
                      <h2 className="text-lg font-bold">Location:</h2>
                      <div className="ml-6 p-2">
                        {locations
                          .sort((a, b) => a.state.localeCompare(b.state))
                          .map((location, index) => (
                            <div key={index} className="flex items-center">
                              <input
                                type="checkbox"
                                id={`location-${index}`}
                                className="mr-2"
                                onChange={() => {
                                  handleFilterCheckboxClick(
                                    `${location.city}, ${location.state}`,
                                    "Location",
                                  );
                                }}
                                checked={filters.locationFilters.includes(
                                  `${location.city}, ${location.state}`,
                                )}
                              />
                              <label htmlFor={`location-${index}`}>
                                {location.city}, {location.state}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    {filter.map((section, index) => (
                      <div key={index} className="p-2">
                        <h2 className="border-b-gray-dark text-lg font-bold">
                          {section.text}:
                        </h2>
                        <div className="ml-6 p-2">
                          {section.content.map((item, index) => (
                            <div key={index} className="flex items-center">
                              <input
                                type="checkbox"
                                className="mr-2"
                                onChange={() => {
                                  handleFilterCheckboxClick(item, section.text);
                                }}
                                checked={getFilterArray(section.text).includes(
                                  item,
                                )}
                              />
                              <label htmlFor={`checkbox-${index}`}>
                                {item}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex w-full flex-col items-center">
                  <h1
                    id="browse-all-dogs"
                    className="mb-10 text-center text-3xl font-bold text-blue"
                  >
                    Browse Pups |{" "}
                    {filters.browseDogsFilter[0] || "Browse All Dogs"}
                  </h1>
                  <div className="mb-6 flex flex-row gap-6">
                    {buttons.map((button, index) => (
                      <button
                        key={`button-${index}`}
                        className={`w-fit text-nowrap rounded-md border-1 border-solid px-12 py-2 font-bold shadow-md ${
                          (button.filterText === "At-Risk" &&
                            filters.browseDogsFilter.includes("At-Risk") &&
                            !filters.browseDogsFilter.includes(
                              "In Rescue/Foster Care",
                            )) ||
                          (button.filterText === "In Rescue/Foster Care" &&
                            filters.browseDogsFilter.includes(
                              "In Rescue/Foster Care",
                            ) &&
                            !filters.browseDogsFilter.includes("At-Risk")) ||
                          (button.filterText === "Browse All Dogs" &&
                            (filters.browseDogsFilter.length === 0 ||
                              filters.browseDogsFilter.includes("") ||
                              filters.browseDogsFilter.includes(
                                "Browse All Dogs",
                              )) &&
                            !filters.browseDogsFilter.includes("At-Risk") &&
                            !filters.browseDogsFilter.includes(
                              "In Rescue/Foster Care",
                            )) ||
                          (button.filterText === "Browse All Dogs" &&
                            filters.browseDogsFilter.includes("At-Risk") &&
                            filters.browseDogsFilter.includes(
                              "In Rescue/Foster Care",
                            ))
                            ? "bg-gray-dark text-white"
                            : "border-gray-dark text-gray-dark"
                        }`}
                        onClick={() => {
                          handleBrowseButtonsClick(
                            button.filterText,
                            "Browse Dogs",
                          );
                        }}
                      >
                        {button.text}
                      </button>
                    ))}
                  </div>
                  {allDogs && allDogs.length > 0 && (
                    <>
                      <div className="grid w-full grid-cols-2 gap-6 xl:grid-cols-3">
                        <>
                          {allDogs.map((dog: publicDogModel) => (
                            <PublicBrowseDogCard
                              key={dog.dog_id + dog.name}
                              dog={dog}
                            />
                          ))}
                        </>
                      </div>
                    </>
                  )}

                  {!allDogs ||
                    (allDogs.length === 0 && (
                      <div className="invisible grid w-full grid-cols-2 gap-6 xl:grid-cols-3">
                        <>
                          {sampleDogs.map((dog: publicDogModel) => (
                            <PublicBrowseDogCard
                              key={dog.dog_id + dog.name}
                              dog={dog}
                            />
                          ))}
                        </>
                      </div>
                    ))}

                  {dataExists && (
                    <div className="mt-16 flex w-full justify-center">
                      <button
                        onClick={handleLoadMoreButton}
                        className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark"
                      >
                        Load More
                      </button>
                    </div>
                  )}
                  {loadingMoreDogs && (
                    <div>
                      <div className="inset-0 z-50 flex h-750 items-center justify-center bg-white bg-opacity-75">
                        <div className="flex flex-col items-center">
                          <div className="loader mb-4 h-32 w-32 rounded-full border-8 border-t-8 border-blue ease-linear"></div>
                          <h2 className="text-2xl font-semibold text-blue">
                            Loading...
                          </h2>
                          <p className="text-blue">
                            Please wait while we fetch these pups.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </>
        </div>
        <div className="flex justify-center md:hidden">
          <>
            <section className="flex flex-col items-center">
              <h1
                id="browse-all-dogs"
                className="text-center text-xl font-bold text-blue"
              >
                Browse Pups | {filters.browseDogsFilter[0] || "Browse All Dogs"}
              </h1>

              <section className="flex flex-row">
                <div className="flex w-full flex-col items-center justify-center">
                  <div className="flex w-[300px] flex-col text-left">
                    <button
                      className="flex w-full flex-row items-center justify-between border border-gray-300 px-4 py-2 text-left text-lg font-bold"
                      onClick={() => toggleSearchDropDown()}
                    >
                      {searchDropDownData.text}
                      {searchDropDownData.expanded ? (
                        <img src={up} alt="Up Arrow" className="h-4 w-4" />
                      ) : (
                        <img src={down} alt="Down Arrow" className="h-4 w-4" />
                      )}
                    </button>
                    {searchDropDownData.expanded && (
                      <div className="w-full border border-t-0 border-gray-300 p-4">
                        <div className="mb-6">
                          <h2 className="mt-2 text-lg font-bold">
                            ID #, Name or Breed:
                          </h2>
                          <input
                            className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                            id="email"
                            type="email"
                            value={params.search}
                            onChange={(e) =>
                              setParams({ ...params, search: e.target.value })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                // Trigger search or any other action
                                retrieveDogs(1, {
                                  ...params,
                                  search: (e.target as HTMLInputElement).value,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="mb-2 mt-2 flex w-full justify-center">
                          <button
                            onClick={() => retrieveDogs(1, params)}
                            className="w-fit w-full text-nowrap rounded-md bg-blue py-2 font-semibold text-white shadow-md hover:bg-blue-dark"
                          >
                            Search
                          </button>
                        </div>

                        <div className="mb-2 flex flex-col gap-1">
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              onChange={() => {
                                handleFilterCheckboxClick(
                                  "At-Risk",
                                  "Browse Dogs",
                                );
                              }}
                              checked={getFilterArray("Browse Dogs").includes(
                                "At-Risk",
                              )}
                              className="mr-2"
                            />
                            <label htmlFor={`checkbox-`}>At-Risk</label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              onChange={() => {
                                handleFilterCheckboxClick(
                                  "In Rescue/Foster Care",
                                  "Browse Dogs",
                                );
                              }}
                              checked={getFilterArray("Browse Dogs").includes(
                                "In Rescue/Foster Care",
                              )}
                              className="mr-2"
                            />
                            <label htmlFor={`checkbox-`}>
                              In Rescue / Foster Care
                            </label>
                          </div>

                          <button className="mt-5 w-fit w-full text-nowrap rounded-md bg-blue py-2 font-semibold text-white shadow-md hover:bg-blue-dark">
                            <Link to="/adopted" className="text-sm font-bold">
                              Adopted Dogs
                            </Link>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-6 mt-6 w-[300px] text-left">
                    <button
                      className="flex w-full flex-row items-center justify-between border border-gray-300 px-4 py-2 text-left text-lg font-bold"
                      onClick={() => toggleFilterDropDown()}
                    >
                      {filterDropDownData.text}
                      {filterDropDownData.expanded ? (
                        <img src={up} alt="Up Arrow" className="h-4 w-4" />
                      ) : (
                        <img src={down} alt="Down Arrow" className="h-4 w-4" />
                      )}
                    </button>
                    {filterDropDownData.expanded && (
                      <div className="mb-6 mr-10 w-[300px] border border-t-0 border-gray-300 text-left">
                        <div className="">
                          <div className="p-2">
                            <h2 className="text-lg font-bold">Location:</h2>
                            <div className="ml-6 p-2">
                              {locations
                                .sort((a, b) => a.state.localeCompare(b.state))
                                .map((location, index) => (
                                  <div
                                    key={`location-${index}`}
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      id={`location-${index}`}
                                      className="mr-2"
                                      onChange={() => {
                                        handleFilterCheckboxClick(
                                          `${location.city}, ${location.state}`,
                                          "Location",
                                        );
                                      }}
                                      checked={filters.locationFilters.includes(
                                        `${location.city}, ${location.state}`,
                                      )}
                                    />
                                    <label htmlFor={`location-${index}`}>
                                      {location.city}, {location.state}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div>
                          {filter.map((section, index) => (
                            <div key={index} className="p-2">
                              <h2 className="border-b-gray-dark text-lg font-bold">
                                {section.text}:
                              </h2>
                              <div className="ml-6 p-2">
                                {section.content.map((item, index) => (
                                  <div
                                    key={item + index}
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      onChange={() => {
                                        handleFilterCheckboxClick(
                                          item,
                                          section.text,
                                        );
                                      }}
                                      checked={getFilterArray(
                                        section.text,
                                      ).includes(item)}
                                    />
                                    <label htmlFor={`checkbox-${index}`}>
                                      {item}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-6 md:grid-cols-3">
                    {allDogs.map((dog: publicDogModel, index) => (
                      <MobilePublicBrowseDogCard
                        key={`${dog.dog_id}-${dog.name}-${index}`}
                        dog={dog}
                      />
                    ))}
                  </div>
                  {dataExists && (
                    <div className="mt-16 flex w-full justify-center">
                      <button
                        onClick={handleLoadMoreButton}
                        className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark"
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              </section>
            </section>
          </>
          <div />
        </div>
      </div>
    </section>
  );
};

export default BrowseDogs;
