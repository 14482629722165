interface SponsorContainerProps {
  name: string;
  image: string;
}

const SponsorContainer: React.FC<SponsorContainerProps> = ({ name, image }) => {
  return (
    <img
      src={image}
      alt={`${name}`}
      className="overflow-cover h-[172px] w-[172px] rounded-full sm:h-[300px] sm:w-[300px]"
    ></img>
  );
};
export default SponsorContainer;
